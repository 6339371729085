import React from 'react'
import { useCookies } from 'react-cookie'

import { useAppSelector } from 'redux/hooks'

import DeckCardWrapper, { DeckCardWrapperChildProps } from 'components/card/deckCards/deckCardWrapper'
import DraggableBasicCard from 'components/card/DraggableBasicCard'
import CornerQuantity from 'components/card/controlsAndInfo/cornerQuantity'
import Prices from 'components/card/controlsAndInfo/prices'
import CornerOwned from 'components/card/controlsAndInfo/cornerOwned'
import CompanionTag from 'components/card/controlsAndInfo/companionTag'
import CanlanderPointsTag from 'components/card/controlsAndInfo/canlanderPointsTag'
import GameChangerLabel from 'components/card/controlsAndInfo/gameChangerLabel'
import Icon from 'components/elements/Icon'
import ContextMenu from 'components/elements/ContextMenu'
import { AntiHighlight, MultiSelected } from 'components/card/controlsAndInfo/cardOverlays'

import { generateContextOptions } from 'components/card/deckCards/deckCardContextMenu'
import { disableExtraLayerKey } from 'components/accountSettingsPage/BrowserSpecificSiteSettings'

import styles from './imageCard.module.scss'

type Props = {
  cardId: string
  wrapperClassName?: string
  className?: string
  imageClassName?: string
  basicCardClass?: string
  stackName?: string
  skipOpenOnClick?: boolean
  hidePrices?: boolean
  simplifiedDOMStack?: boolean
  hideControlsUnlessHovered?: boolean
}

const ImageCard = ({
  cardId,
  stackName,
  className = '',
  basicCardClass = '',
  imageClassName = '',
  wrapperClassName = '',
  skipOpenOnClick = false,
  hidePrices = false,
  simplifiedDOMStack = false,
  hideControlsUnlessHovered = false,
}: Props) => (
  <DeckCardWrapper
    cardId={cardId}
    stackName={stackName}
    component={Card}
    extraData={{
      className,
      basicCardClass,
      wrapperClassName,
      skipOpenOnClick,
      hidePrices,
      imageClassName,
      simplifiedDOMStack,
      hideControlsUnlessHovered,
    }}
  />
)

export default ImageCard

type ArchidektViewImageCardProps = { style?: React.CSSProperties } & Props

// Wraps the image card so it's possible to use transitions in the traditional "archidekt view" (stacks) (hover moves the card down view)
// Without this, the cards act as just 'normal' elements
export const ArchidektViewImageCard = (props: ArchidektViewImageCardProps) => (
  <div id={`deck-card-dom-${props.cardId}`} className={`${styles.container}`} style={props.style}>
    <ImageCard {...props} skipOpenOnClick />
  </div>
)

const Card = ({ card, stackName, localCardData, actions, extraData, deckData }: DeckCardWrapperChildProps) => {
  const multiSelectedIds = useAppSelector(state => state.active.multiSelectedIds)
  const options = generateContextOptions({ card, actions, localCardData, deckData, extraData })
  const [{ [disableExtraLayerKey]: disableAnimatedLayers }] = useCookies([disableExtraLayerKey])

  return (
    <div
      className={`
        ${styles.imageCard}
        ${extraData.hideControlsUnlessHovered ? styles.hideControlsWithoutHovered : ''}
        ${extraData.className}
      `}>
      <DraggableBasicCard
        className={extraData.basicCardClass}
        imageClassName={extraData.imageClassName}
        cardLoaderClassName={styles.cardLoader}
        card={card}
        flipped={localCardData.flipped}
        error={localCardData.cardError}
        faded={localCardData.inSecondaryCategory}
        onClick={e => actions.cardClick(e, extraData.skipOpenOnClick)}
        onContextMenu={actions.rightClick}
        onDoubleClick={() => (localCardData.anyMultiSelected ? null : actions.openCardModal())}
        dragItem={{ card, stackName, multiSelectedIds: localCardData.isMultiSelected ? multiSelectedIds : undefined }}
      />

      {!extraData.simplifiedDOMStack && (
        <>
          {/* Some phones don't play nice with the animated opacity and it'll get stuck */}
          {`${disableAnimatedLayers}` !== 'true' ? (
            <>
              <MultiSelected visible={localCardData.isMultiSelected} />
              <AntiHighlight visible={localCardData.antiHighlight} />
            </>
          ) : (
            <>
              {localCardData.isMultiSelected && <MultiSelected visible={localCardData.isMultiSelected} simple />}
              {localCardData.antiHighlight && <AntiHighlight visible={localCardData.antiHighlight} />}
            </>
          )}

          {!!card.qty && (
            <CornerQuantity
              card={{ ...card, qty: localCardData.quantity }}
              loader={localCardData.saving === 'saving'}
              onCardUpdate={actions.onCardUpdate}
            />
          )}

          <CornerOwned owned={card.owned} />

          {!localCardData.anyMultiSelected && (
            <>
              <GameChangerLabel card={card} />
              <CompanionTag card={card} />
              <CanlanderPointsTag card={card} />

              {localCardData.isFlipCard && (
                <div className={styles.filpButtonContainer}>
                  <button onClick={() => actions.setFlipped(!localCardData.flipped)}>
                    <Icon name="refresh" />
                  </button>
                </div>
              )}

              {!extraData.simplifiedDOMStack && (
                <>
                  <div className={styles.controls}>
                    {localCardData.ownsDeck && (
                      <>
                        <button className={styles.increaseButton} onClick={actions.incrementCard}>
                          <Icon name="add" />
                        </button>
                        <button className={styles.decreaseButton} onClick={actions.decrementCard}>
                          <Icon name="minus" />
                        </button>
                      </>
                    )}
                    <button className={styles.cardDetailsButton} onClick={() => actions.openCardModal()}>
                      <Icon name="chevron right" />
                    </button>
                  </div>

                  {!extraData.hidePrices && (
                    <div className={styles.extrasContainer} style={{ opacity: localCardData.antiHighlight ? 0.15 : 1 }}>
                      <div className={styles.extras}>
                        <Prices card={card} />

                        <ContextMenu menuClassName={styles.extrasMenu} options={options} openOnLeftClick>
                          <button className={styles.extrasTrigger}>
                            <Icon name="ellipsis horizontal" />
                          </button>
                        </ContextMenu>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}
