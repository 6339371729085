import React from 'react'
import { Icon, SemanticICONS } from 'semantic-ui-react'

import ArchidektDropdown from 'components/elements/ArchidektDropdown'
import { Option } from 'components/elements/ArchidektDropdown/types'

import styles from './phatDropdown.module.scss'

export type Props = {
  value: any
  header?: React.ReactNode
  icon?: SemanticICONS
  options: Option[]
  extraOptions?: Option[]
  selectedLabel?: React.ReactNode
  className?: string
  triggerClassName?: string
  triggerWrapperClassName?: string
  menuClassName?: string
  extraOptionsElement?: React.ReactNode
  disabled?: boolean
  inlineHeader?: boolean
  placeholder?: string
  onFocus?: () => void
  asteriskLabel?: string
}

const PhatDropdown = ({
  header,
  options,
  icon,
  value,
  extraOptions,
  extraOptionsElement = null,
  selectedLabel = null,
  className = '',
  triggerClassName = '',
  triggerWrapperClassName = '',
  menuClassName,
  disabled = false,
  inlineHeader = false,
  placeholder,
  asteriskLabel,
  onFocus,
}: Props) => (
  <div className={`${styles.container} ${inlineHeader ? styles.inlineContainer : ''} ${className}`}>
    {header && <label className={`${styles.header} ${inlineHeader ? styles.inlineHeader : ''}`}>{header}</label>}

    <ArchidektDropdown
      disabled={disabled}
      onFocus={onFocus}
      options={options}
      menuClassName={`${styles.menu} ${menuClassName}`}
      triggerClassName={`${styles.triggerWrapper} ${triggerWrapperClassName}`}>
      <button
        disabled={disabled}
        className={`
          ${styles.trigger}
          ${extraOptions || extraOptionsElement ? styles.hasExtraOptions : ''}
          ${triggerClassName}
        `}
        type="button">
        <span className={styles.leftContent}>
          {icon && <Icon name={icon} className={styles.icon} />}
          {!selectedLabel && value !== undefined && (
            <>
              {/* @ts-ignore */}
              {options.find(o => `${o.id}` === `${value}`)?.label || null}
            </>
          )}
          {selectedLabel}
          {placeholder && !value && <span className={styles.placeholder}>{placeholder}</span>}
        </span>
        <Icon name="triangle down" />
      </button>
    </ArchidektDropdown>

    {asteriskLabel && <label className={styles.asteriskLabel}>{asteriskLabel}</label>}

    {!!extraOptions && (
      <ArchidektDropdown options={extraOptions}>
        <button className={styles.extraTrigger} type="button">
          <Icon name="cog" />
        </button>
      </ArchidektDropdown>
    )}

    {extraOptionsElement}
  </div>
)

export default PhatDropdown
