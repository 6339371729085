import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'

import StreamAd from 'components/advertisments/StreamAd'

import { ROLES } from 'types/user'

import Icon from 'components/elements/Icon'
import { CustomAdInfo, getAdInfo } from 'components/advertisments/CustomAd'

import styles from './fixedAd.module.scss'

type Props = {
  id: string
  className?: string
}

const FixedAd = ({ id, className }: Props) => {
  const router = useRouter()

  const [closed, setClosed] = useState(false)
  const [customAd, setCustomAd] = useState<CustomAdInfo | null>(null)

  const [{ tbTier: tier, tbR: roles = [] }] = useCookies(['tbTier', 'tbR'])

  const hideAds = Number(tier) || roles.includes(ROLES.AD_FREE)

  useEffect(() => {
    setClosed(false)
  }, [router.asPath])

  useEffect(() => {
    const ad = getAdInfo(['tcg'])

    ReactGA.event({ category: 'Ad', action: 'Rendered fixed mobile ad TOTAL' })
    ReactGA.event({ category: 'Ad', action: `Rendered fixed mobile ad - ${ad.adType}` })

    setCustomAd(ad)
  }, [])

  const handleGALog = () => {
    ReactGA.event({ category: 'Ad', action: `Clicked ${customAd?.adType} fixed mobile ad` })
  }

  if (hideAds) return null
  if (closed) return null

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.adContainer} id={id}>
        <button type="button" className={styles.close} onClick={() => setClosed(true)}>
          <Icon name="close" />
        </button>

        <a href="https://www.patreon.com/archidekt" rel="noopener noreferrer" target="_blank">
          <Icon name="patreon" />
          Support us on Patreon to remove ads!
        </a>

        <StreamAd />

        {customAd && (
          <a
            href={customAd.adMobileHref ?? customAd.adHref}
            target="_blank"
            className={styles.mobileAdheasion}
            onClick={handleGALog}>
            <img src={customAd.adMobileUrl} />
          </a>
        )}
      </div>
    </div>
  )
}

export default FixedAd
