import { isEqual } from 'lodash'

import { CardType, SORT } from 'types/deck'
import { getCardCMC } from 'utils/GetCardCMC'
import { getPrice } from 'utils/Price'
import { PriceSourceType } from 'types/active'
import { getCardDefaultCategoryFromCard } from 'utils/CategoryGenerator'
import { colorsToColorIdentityName, getIdentityNameOrdering } from 'utils/colorUtils'

export const alpha = (a: CardType, b: CardType, _priceSource: PriceSourceType = [1]) => {
  const sameName = a.name === b.name
  const sameSetDate = a.setDate === b.setDate

  if (sameName && sameSetDate) return a.collectorNumber > b.collectorNumber ? 1 : -1
  if (sameName && !sameSetDate) return a.setDate > b.setDate ? 1 : -1

  return a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1
}

export const cmc = (a: CardType, b: CardType, priceSource?: PriceSourceType) => {
  const cmcA = getCardCMC(a)
  const cmcB = getCardCMC(b)

  if (cmcA === cmcB) return alpha(a, b, priceSource)

  return cmcA > cmcB ? 1 : -1
}

export const price = (a: CardType, b: CardType, priceSource: PriceSourceType) => {
  const priceA = getPrice(a, priceSource[0]).priceNumber
  const priceB = getPrice(b, priceSource[0]).priceNumber

  if (priceA === priceB) return alpha(a, b, priceSource)

  return priceA > priceB ? 1 : -1
}

export const set = (a: CardType, b: CardType, priceSource?: PriceSourceType) => {
  if (a.set === b.set) return alpha(a, b, priceSource)

  return a.set > b.set ? 1 : -1
}

export const color = (a: CardType, b: CardType, priceSource?: PriceSourceType) => {
  if (isEqual(a.colors, b.colors)) return alpha(a, b, priceSource)

  const aColorName = colorsToColorIdentityName(a.colors)
  const bColorName = colorsToColorIdentityName(b.colors)

  return getIdentityNameOrdering(aColorName) > getIdentityNameOrdering(bColorName) ? 1 : -1
}

export const colorIdentity = (a: CardType, b: CardType, priceSource?: PriceSourceType) => {
  if (isEqual(a.colorIdentity, b.colorIdentity)) return alpha(a, b, priceSource)

  const aIdentName = colorsToColorIdentityName(a.colorIdentity)
  const bIdentName = colorsToColorIdentityName(b.colorIdentity)

  return getIdentityNameOrdering(aIdentName) > getIdentityNameOrdering(bIdentName) ? 1 : -1
}

export const colorLabel = (a: CardType, b: CardType, priceSource?: PriceSourceType) => {
  if (a.colorLabel.name === b.colorLabel.name) return alpha(a, b, priceSource)

  return a.colorLabel.name > b.colorLabel.name ? 1 : -1
}

export const collection = (a: CardType, b: CardType, priceSource?: PriceSourceType) => {
  if (a.owned === b.owned) return alpha(a, b, priceSource)

  return a.owned > b.owned ? 1 : -1
}

export const salt = (a: CardType, b: CardType, priceSource?: PriceSourceType) => {
  if (a.salt === b.salt) return alpha(a, b, priceSource)

  return a.salt > b.salt ? 1 : -1
}

export const edhrecRank = (a: CardType, b: CardType, priceSource?: PriceSourceType) => {
  if (a.edhrecRank === b.edhrecRank) return alpha(a, b, priceSource)

  return (a.edhrecRank || 9999999) > (b.edhrecRank || 9999999) ? 1 : -1
}

export const dateAdded = (a: CardType, b: CardType, priceSource?: PriceSourceType) => {
  if (a.createdAt === b.createdAt) return alpha(a, b, priceSource)

  // Unsaved cards have no date, just order them arbirarilly
  if (!a.createdAt) return -1
  if (!b.createdAt) return 1

  return a.createdAt > b.createdAt ? -1 : 1 // note that this is descending for date added, not ascending
}

export const cardType = (a: CardType, b: CardType, priceSource?: PriceSourceType) => {
  const OptionsOrder: Record<string, number> = {
    'Creature': 1,
    'Artifact': 2,
    'Enchantment': 3,
    'Instant': 4,
    'Sorcery': 5,
    'Planeswalker': 6,
    'Land': 7,
  }

  const aDefaultCardType = getCardDefaultCategoryFromCard(a)
  const bDefaultCardType = getCardDefaultCategoryFromCard(b)

  if (aDefaultCardType === bDefaultCardType) return alpha(a, b, priceSource)

  if (!aDefaultCardType) return 1
  if (!bDefaultCardType) return -1

  return (OptionsOrder[aDefaultCardType] || 1000) < (OptionsOrder[bDefaultCardType] || 1000) ? -1 : 1
}

export const power = (a: CardType, b: CardType, priceSource?: PriceSourceType) => {
  if (a.power == null && a.front?.power == null) return -1
  if (b.power == null && b.front?.power == null) return 1

  let aPower = Number(a.power || a.front?.power)
  let bPower = Number(b.power || b.front?.power)

  if (Number.isNaN(aPower)) aPower = 1000
  if (Number.isNaN(bPower)) bPower = 1000

  if (aPower === bPower) return alpha(a, b, priceSource)

  return aPower > bPower ? 1 : -1
}

export const toughness = (a: CardType, b: CardType, priceSource?: PriceSourceType) => {
  if (a.toughness == null && a.front?.toughness == null) return -1
  if (b.toughness == null && b.front?.toughness == null) return 1

  let aToughness = Number(a.toughness || a.front?.toughness)
  let bToughness = Number(b.toughness || b.front?.toughness)

  if (Number.isNaN(aToughness)) aToughness = 1000
  if (Number.isNaN(bToughness)) bToughness = 1000

  if (aToughness === bToughness) return alpha(a, b, priceSource)

  return aToughness > bToughness ? 1 : -1
}

export const categoryQuantity = (a: CardType, b: CardType, priceSource?: PriceSourceType) => {
  if (a.categories.length === b.categories.length) return alpha(a, b, priceSource)

  return a.categories.length > b.categories.length ? 1 : -1
}

export const cardModifier = (a: CardType, b: CardType, priceSource?: PriceSourceType) => {
  if (a.modifier === b.modifier) return alpha(a, b, priceSource)

  const OptionsOrder: Record<string, number> = {
    'normal': 1,
    'nonfoil': 2,
    'foil': 3,
    'etched': 4,
  }

  const aModifier = OptionsOrder[a.modifier?.toLocaleLowerCase()] || 1000
  const bModifier = OptionsOrder[b.modifier?.toLocaleLowerCase()] || 1000

  return aModifier < bModifier ? -1 : 1
}

export const SortFunctionMap = {
  [`${SORT.ALPHA}`]: alpha,
  [`${SORT.CMC}`]: cmc,
  [`${SORT.PRICE}`]: price,
  [`${SORT.SET}`]: set,
  [`${SORT.COLOR}`]: color,
  [`${SORT.IDENTITY}`]: colorIdentity,
  [`${SORT.COLOR_LABEL}`]: colorLabel,
  [`${SORT.COLLECTION}`]: collection,
  [`${SORT.SALT}`]: salt,
  [`${SORT.EDHREC_RANK}`]: edhrecRank,
  [`${SORT.DATE_ADDED}`]: dateAdded,
  [`${SORT.TYPE}`]: cardType,
  [`${SORT.TOUGHNESS}`]: toughness,
  [`${SORT.POWER}`]: power,
  [`${SORT.CATEGORY_QUANTITY}`]: categoryQuantity,
  [`${SORT.CARD_MODIFIER}`]: cardModifier,
}

export const orderByNumericStackHeader = (a: string | number, b: string | number) => {
  if (a === 'N/A') return 1
  if (b === 'N/A') return -1

  let aNumeric = Number(a)
  let bNumberic = Number(b)

  if (Number.isNaN(aNumeric)) aNumeric = 1000
  if (Number.isNaN(bNumberic)) bNumberic = 1000

  if (aNumeric === bNumberic) return a > b ? 1 : -1

  return aNumeric > bNumberic ? 1 : -1
}

export const orderByLandCategoryLast = (a: string | number, b: string | number) => {
  if (a === 'Land') return 1000
  if (b === 'Land') return -1000

  return a > b ? 1 : -1
}
