import { PriceSourceType } from 'redux/active/actions/types'
import { CardType } from 'types/deck'
import { Color } from 'services/apiTypes/card.types'

import { getPrice, getPriceStringFromFloat } from 'utils/Price'
import { colorsToColorIdentityName } from 'utils/colorUtils'

export function getPriceRange(card: CardType, priceSources: PriceSourceType) {
  const priceSource = priceSources[0]
  const { priceNumber } = getPrice(card, priceSource, card.modifier !== 'Normal')

  const format = (n: number) => getPriceStringFromFloat(n, priceSource)

  if (priceNumber === 0) return 'Unknown'
  if (priceNumber < 0.5) return `${format(0)} - ${format(0.5)}`
  if (priceNumber < 1) return `${format(0.5)} - ${format(1)}`
  if (priceNumber < 5) return `${format(1)} - ${format(5)}`
  if (priceNumber < 10) return `${format(5)} - ${format(10)}`
  if (priceNumber < 20) return `${format(10)} - ${format(20)}`
  if (priceNumber < 50) return `${format(20)} - ${format(50)}`
  if (priceNumber < 100) return `${format(50)} - ${format(100)}`

  return `${format(100)}+`
}

export function getSaltRange(card: CardType) {
  if (card.salt < 0.1) return '0 - 0.1'
  if (card.salt < 0.25) return '0.10 - .25'
  if (card.salt < 0.5) return '0.25 - .5'
  if (card.salt < 1) return '0.50 - 1'
  if (card.salt < 2) return '1 - 2'
  if (card.salt < 3) return '2 - 3'

  return '3+'
}

export function getColors(colors: Color[]) {
  return colorsToColorIdentityName(colors)
}

export function getColorsForSpellsOnly(card: CardType) {
  const isLand = card.typeCategory === 'Land'

  if (isLand) return 'Land'

  const colors = [...card.colors, ...(card.front?.colors || []), ...(card.back?.colors || [])]

  return getColors(colors)
}

export function getEDHBracketInfo(card: CardType) {
  if (card.gameChanger) return 'Game Changer'
  if (card.extraTurns) return 'Extra Turns'
  if (card.massLandDenial) return 'Mass Land Denial'
  if (card.tutor) return 'Non-Land Tutor'

  return 'Unlabeled'
}

export function getTimeFrames(timestamp: string | undefined | null) {
  const now = new Date()
  const time = timestamp ? new Date(timestamp) : new Date() // use now as a default for unsaved cards

  const sixtySeconds = 1000 * 60
  const tenMinutes = sixtySeconds * 10
  const oneHour = tenMinutes * 6
  const oneDay = oneHour * 24
  const twoDays = oneDay * 2
  const oneWeek = oneDay * 7
  const oneMonth = oneDay * 30
  const twoMonths = oneMonth * 2
  const sixMonths = (365 / 2) * oneDay
  const oneYear = 365 * oneDay
  const twoYears = oneYear * 2

  if (time.getTime() > now.getTime() - sixtySeconds) return 'Less than 1 minute'
  if (time.getTime() > now.getTime() - tenMinutes) return '10 Minutes'
  if (time.getTime() > now.getTime() - oneHour) return '1 Hour'
  if (time.getTime() > now.getTime() - oneDay) return '1 Day'
  if (time.getTime() > now.getTime() - twoDays) return '2 Days'
  if (time.getTime() > now.getTime() - oneWeek) return '7 Days'
  if (time.getTime() > now.getTime() - oneMonth) return '1 Month'
  if (time.getTime() > now.getTime() - twoMonths) return '2 Months'
  if (time.getTime() > now.getTime() - sixMonths) return '6 Months'
  if (time.getTime() > now.getTime() - oneYear) return '1 Year'
  if (time.getTime() > now.getTime() - twoYears) return '2 Years'

  return '2 Years+'
}

export const TIME_RANGES: Record<string, number> = {
  'Less than 1 minute': 1,
  '10 Minutes': 2,
  '1 Hour': 3,
  '1 Day': 4,
  '2 Days': 5,
  '7 Days': 6,
  '1 Month': 7,
  '2 Months': 8,
  '1 Year': 9,
  '2 Years': 10,
  '2 Years+': 11,
}
