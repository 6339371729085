import React, { useEffect, useRef, useState } from 'react'
import Icon from 'components/elements/Icon'
import ReactDOM from 'react-dom'
import shortid from 'shortid'

import { useActionless, useAppSelector } from 'redux/hooks'

import { SET_ACTIVE_STATE } from 'redux/active/actions/types'

import { basicKeybindSkips } from 'components/deckPage/modals/KeybindsOverlay'

import styles from './globalOverlayStack.module.scss'

type Props = {
  open: boolean
  onClose: () => void
  children: React.ReactNode
  className?: string
  mobileControls?: React.ReactNode
  mobileControlsClassName?: string
  backButtonText?: string
  remainMounted?: boolean
  id?: string
  noWeirdClose?: boolean
}

const TRANSITION_TIME = 400 // Should match the transition time in the CSS

const GlobalOverlayStack = React.forwardRef(
  (
    {
      open,
      onClose,
      children,
      className = '',
      mobileControls,
      mobileControlsClassName = '',
      backButtonText,
      remainMounted,
      noWeirdClose = false,
      id: parentId,
    }: Props,
    parentRef: any,
  ) => {
    if (typeof document === 'undefined') return null

    const [id] = useState(parentId || shortid.generate())

    const openRef = useRef(open)

    const [ready, setReady] = useState(false) // use this rather than open. Open is used from the parent, but we need to append the ID to the stackOverlayIds before we can show the content
    const [visable, setVisable] = useState(false)
    const [contentVisable, setContentVisable] = useState(false) // used to transition the content, not the background
    const [closeClickStarted, setCloseClickStarted] = useState(false)

    const [isActiveStackElement, setIsActiveStackElement] = useState(false)

    const stackOverlayIds = useAppSelector(state => state.active.stackOverlayIds)
    const hideStackOverlay = useAppSelector(state => state.active.hideStackOverlay)

    const [setActiveState] = useActionless(SET_ACTIVE_STATE)

    useEffect(() => {
      document.addEventListener('keydown', handleKeyDown)

      return () => {
        document.removeEventListener('keydown', handleKeyDown)

        // This is all part of a failsafe cleanup process.
        // This way if the overlay is removed from the DOM without having been closed, it will still be removed from the stack
        if (!openRef.current) return

        openRef.current = false
        setActiveState({ stackOverlayIds: stackOverlayIds.filter(overlayId => overlayId !== id) })
      }
    }, [])

    useEffect(() => {
      if (!open && !visable) return

      openRef.current = open

      if (open) {
        document.body.classList.add('no-scrolling')

        setActiveState({ stackOverlayIds: [...stackOverlayIds, id] })
        setReady(true)

        setTimeout(() => {
          setVisable(true)
          setContentVisable(true)
        }, TRANSITION_TIME)
      } else {
        if (isRootComponent) document.body.classList.remove('no-scrolling')

        setVisable(false)
        setContentVisable(false)

        setTimeout(() => {
          setActiveState({ stackOverlayIds: stackOverlayIds.filter(overlayId => overlayId !== id) })
          setReady(false)
        }, TRANSITION_TIME)
      }
    }, [open])

    useEffect(() => {
      if (!open && !visable) return

      const isActiveStackElement = stackOverlayIds[stackOverlayIds.length - 1] === id

      if (isActiveStackElement) {
        setIsActiveStackElement(isActiveStackElement)
        setTimeout(() => setContentVisable(true), TRANSITION_TIME / 2)
      } else {
        setContentVisable(false)
        setTimeout(() => setIsActiveStackElement(isActiveStackElement), TRANSITION_TIME / 2)
      }
    }, [stackOverlayIds])

    const handleKeyDown = (e: KeyboardEvent) => {
      if (!onClose) return
      if (openRef.current === false) return

      if (basicKeybindSkips(e)) return

      if (e.key === 'Escape') {
        e.preventDefault() // prevents exiting fullscreen on macos (I think)

        onClose()
      }
    }

    // Using the built in onClick means that if you start the click in the modal and end it in the dimmer area it closes
    // the modal. This is frustrating when selecting text in an input field. To avoid this we track if the click started
    // in the dimmer area and only close the modal if it did and it also ended in the dimmer area.
    const handleWeirdCloseMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
      if (noWeirdClose) return
      if (e.target === e.currentTarget) setCloseClickStarted(true)
      else setCloseClickStarted(false)
    }
    const handleWeirdCloseMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
      if (noWeirdClose) return
      if (e.target === e.currentTarget && closeClickStarted) onClose && onClose()
      setCloseClickStarted(false)
    }

    if (!ready && !visable && !remainMounted) return null

    const isRootComponent = stackOverlayIds[0] === id

    return ReactDOM.createPortal(
      <div
        id={id}
        className={`
          ${styles.overlay}
          ${isRootComponent ? styles.rootOverlay : ''}
          ${visable && !hideStackOverlay ? styles.visable : ''}
        `}>
        <div
          ref={parentRef}
          onMouseDown={handleWeirdCloseMouseDown}
          onMouseUp={handleWeirdCloseMouseUp}
          className={`
          ${styles.content}
          ${contentVisable && !hideStackOverlay ? styles.visable : ''}
          ${!isActiveStackElement ? styles.inactive : ''}
        `}>
          <div className={`${styles.primaryContent} ${className}`}>{children}</div>
        </div>

        <div className={styles.backButtonContainer}>
          <div
            className={`
          ${styles.backButtonContent}
          ${contentVisable ? styles.visable : ''}
          ${!isActiveStackElement ? styles.inactive : ''}
        `}>
            <button className={styles.back} onClick={onClose}>
              {isRootComponent && (
                <>
                  <Icon name="close" /> <span>Close</span>
                </>
              )}

              {!isRootComponent && (
                <>
                  <Icon name="arrow left" /> <span>Back</span>
                </>
              )}
            </button>

            <span className={`${styles.mobileControls} ${mobileControlsClassName}`}>{mobileControls}</span>
          </div>
        </div>

        <div className={styles.grid} />
      </div>,
      document.body,
    )
  },
)

export default GlobalOverlayStack
