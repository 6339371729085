import { DeckCardInfoType } from 'types/search'

import { SubfolderFolderItemType } from 'types/folders'
import { getCachedState } from 'redux/folders/helpers/cachedValues'

export type Folder = {
  id: number
  parentFolder: {
    id: number
    name: string
  }
  owner: {
    username: string
    id: number
    avatar: string
    frame: string
  }
  name: string
  private: boolean
  subfolders: Array<SubfolderFolderItemType>
  decks: Array<DeckCardInfoType>
  count: number
  next: string | null
}

export type FoldersState = {
  rootFolder: Folder

  selectedDecks: Record<number, boolean>
  selectedSubfolders: Record<number, boolean>

  newFolderModalOpen: boolean
  editMultipleDecksSettingsOpen: boolean

  autoColumns: boolean
  columnData: FolderColumnOption[]
}

export const generateInitialFolderState = (serverCookies?: Record<string, any> | string): FoldersState => ({
  rootFolder: {
    id: 0,
    parentFolder: {
      id: 0,
      name: '',
    },
    owner: {
      username: '',
      id: 0,
      avatar: '',
      frame: '',
    },
    name: '',
    private: false,
    subfolders: [],
    decks: [],
    count: 0,
    next: null,
  },

  selectedDecks: {},
  selectedSubfolders: {},

  newFolderModalOpen: false,
  editMultipleDecksSettingsOpen: false,

  autoColumns: true,
  columnData: defaultColumnData,

  ...getCachedState(serverCookies),
})

export const defaultColumnData: FolderColumnOption[] = [
  { label: 'Drag handle', key: 'dragHandle', enabled: true, width: 35 },
  { label: 'Multi-select checkbox', key: 'multiSelectCheckbox', enabled: true, width: 35 },
  { label: 'Name', key: 'name', enabled: true, width: 300 },
  { label: 'Private', key: 'private', enabled: true, width: 50 },
  { label: 'Unlisted', key: 'unlisted', enabled: true, width: 60 },
  { label: 'Theorycrafted', key: 'theorycrafted', enabled: true, width: 100 },
  { label: 'Format', key: 'format', enabled: true, width: 125 },
  { label: 'EDH bracket', key: 'edhBracket', enabled: true, width: 125 },
  { label: 'Updated at', key: 'updatedAt', enabled: true, width: 125 },
  { label: 'Created at', key: 'createdAt', enabled: true, width: 125 },
  { label: 'Colors', key: 'colors', enabled: true, width: 125 },
  { label: 'Tags', key: 'tags', enabled: true, width: 500 },
  { label: 'Menu', key: 'controls', enabled: true, width: 50 },
]

export type FolderColumn =
  | 'name'
  | 'format'
  | 'dragHandle'
  | 'edhBracket'
  | 'multiSelectCheckbox'
  | 'private'
  | 'unlisted'
  | 'theorycrafted'
  | 'updatedAt'
  | 'createdAt'
  | 'tags'
  | 'colors'
  | 'controls'

export type FolderColumnOption = {
  label: string
  key: FolderColumn
  enabled: boolean
  width?: number
}

export type SaturatedFolderColumnOptions =
  | React.ReactNode
  | {
      content?: React.ReactNode
      onClick?: () => void
    }
