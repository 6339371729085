import React, { useState, useEffect } from 'react'
import Icon from 'components/elements/Icon'

import styles from './accordion.module.scss'

type Props = {
  label: React.ReactNode
  children: React.ReactNode
  className?: string
  containerClass?: string
  value?: boolean
  onChange?: (value: boolean) => void
  defaultOpen?: boolean
}

const Accordion = ({
  label,
  children,
  className = '',
  containerClass = '',
  value = false,
  onChange,
  defaultOpen,
}: Props) => {
  // the accordion content is unmounted until the first time is open
  // Once it's opened once it'll stay in the dom as long as the accordion is mounted
  // This prevents loading accordion content that may never been seen
  const [mounted, setMounted] = useState(false)
  const [open, setOpen] = useState(defaultOpen ?? value)

  const handleChangeState = () => {
    setOpen(!open)

    if (onChange) onChange(!open)
  }

  useEffect(() => {
    if (open && !mounted) setMounted(true)
  }, [open])

  // Used if this is a contorlled component
  useEffect(() => {
    if (defaultOpen) return
    if (value === open) return

    setOpen(value)
  }, [value])

  return (
    <>
      <button className={`${styles.trigger} ${className}`} type="button" onClick={handleChangeState}>
        <Icon name="chevron right" className={`${styles.icon} ${open ? styles.rotate : ''}`} /> {label}
      </button>

      {mounted && (
        <div style={{ display: open ? undefined : 'none' }} className={containerClass}>
          {children}
        </div>
      )}
    </>
  )
}

export default Accordion
