import React, { memo, useMemo, useState } from 'react'
import Icon from 'components/elements/Icon'
import { isEqual } from 'lodash'
import debounce from 'lodash.debounce'
import shortid from 'shortid'
import { useCookies } from 'react-cookie'

import { DOUBLE_FACED_LAYOUTS } from 'types/card'

import SaveService from 'services/save.service'

import { useCanEditDeck } from 'redux/deck/selectors'
import { useActionless, useAppSelector } from 'redux/hooks'
import { SET_ACTIVE_STATE } from 'redux/active/actions/types'

import ArchidektDropdown from 'components/elements/ArchidektDropdown'
import CornerOwned from 'components/card/controlsAndInfo/cornerOwned'
import CompanionTag from 'components/card/controlsAndInfo/companionTag'
import BasicCard from 'components/card/BasicCard'
import DraggableBasicCard from 'components/card/DraggableBasicCard'
import Prices from 'components/card/controlsAndInfo/prices'

import { CardType, EDH_FORMATS } from 'types/deck'

import styles from './searchedCard.module.scss'

type Props = {
  card: CardType
  onCardClick: (card: CardType, forceAllLayoutsView?: boolean) => void
  onCardUpdated?: (currentId: string, updatedCard: CardType) => void
  allowDragging?: boolean
  hidePrices?: boolean
  className?: string
  onDragStart?: () => void
  onDragStop?: () => void
  editionControls?: boolean
}

// The card that appears in the search grid (lightly tweaked UI among other options unique to search)
// This is used in both the normal grid and editions grid
const SearchedCard = memo(
  ({
    card,
    onCardClick,
    onCardUpdated,
    allowDragging,
    hidePrices,
    className = '',
    onDragStart,
    onDragStop,
    editionControls,
  }: Props) => {
    const [{ tbUser: username }] = useCookies(['tbUser'])

    const isDeckPage = typeof window !== 'undefined' && location.pathname.startsWith('/decks/')

    const canEditDeck = useCanEditDeck()
    const format = useAppSelector(state => state.deck.format)
    const errorCard = useAppSelector(state => state.deck.warningCards[card.name])
    const categories = useAppSelector(state => state.deck.categories)

    const [setActiveState] = useActionless(SET_ACTIVE_STATE)

    const [localQuantity, setLocalQuantity] = useState(card.qty) // used while debouncing fast quantity changes
    const [flipped, setFlipped] = useState(card.flippedDefault)

    const handleCardClick = (forceAllLayoutsView = false) => {
      if (onCardClick) onCardClick(card, forceAllLayoutsView)
    }

    const handleMoveTo = (card: CardType, categoryName: string, keepOtherCategories = true) => {
      if (!onCardUpdated) return
      if (card.categories.includes(categoryName)) return

      const updatedCategories = keepOtherCategories ? [categoryName, ...card.categories] : [categoryName]

      onCardUpdated(card.id, { ...card, qty: card.qty || 1, categories: updatedCategories })
    }

    const handleHardAddNewEdition = (card: CardType) =>
      SaveService.save({ ...card, id: shortid.generate(), deckRelationId: '', qty: 1 })

    const handleViewAllPrintings = (card: CardType) => {
      if (onCardClick) onCardClick(card, true)
    }

    const handleSetDebouncedQty = (qty: number) => {
      if (onCardUpdated) onCardUpdated(card.id, { ...card, qty })
    }

    // Reset local state if the cards quantity changes someway that isn't here
    // Reset the debounce method to make sure it has correct card info (since it's only updated when lodash.debounce is called)
    const debouncedQtyChange = useMemo(() => {
      if (card.qty !== localQuantity) setLocalQuantity(card.qty)

      return debounce(handleSetDebouncedQty, 660)
    }, [card])

    const handleAddCard = () => {
      setLocalQuantity(localQuantity + 1)
      debouncedQtyChange(localQuantity + 1)
    }

    const handleRemoveCard = () => {
      setLocalQuantity(localQuantity - 1 > 0 ? localQuantity - 1 : 0)
      debouncedQtyChange(localQuantity - 1)
    }

    const handleDragStart = () => {
      // if (isSearchLocked) return

      if (!onDragStart) return setActiveState({ hideStackOverlay: true })

      onDragStart()
    }

    const handleDragStop = () => {
      // if (isSearchLocked) return

      if (!onDragStop) return setActiveState({ hideStackOverlay: false })

      onDragStop()
    }

    const isCommanderFormat = EDH_FORMATS.includes(format)

    // prettier-ignore
    const addSide = (<><Icon name={card.qty ? 'arrow right' : 'add'} /> Sideboard</>)
    // prettier-ignore
    const addMaybe = (<><Icon name={card.qty ? 'arrow right' : 'add'} /> Maybeboard</>)
    // prettier-ignore
    const addCommander = (<><Icon name="chess queen" /> Set Commander</>)
    // prettier-ignore
    const viewMore = (<><Icon name="arrow right" /> Card Details</>)

    // prettier-ignore
    const switchToEdition = (<><Icon name="arrow right" /> Switch to edition</>)
    // prettier-ignore
    const addNewEdition = (<><Icon name="add" /> Add as new card</>)
    // prettier-ignore
    const allPrintings = (<><Icon name="grid layout" /> All printings</>)

    return (
      <div className={`${styles.card} ${className}`} title={`${card.name} (${card.setCode}) ${card.collectorNumber}`}>
        <button className={styles.cardButton} onClick={() => handleCardClick()}>
          {isDeckPage && allowDragging ? (
            <DraggableBasicCard
              card={card}
              className={styles.basicCard}
              imageClassName={styles.cardImage}
              error={errorCard}
              flipped={flipped}
              onDragStart={handleDragStart}
              onDragEnd={handleDragStop}
            />
          ) : (
            <BasicCard
              forceOnlyShowFaceUpSide
              className={styles.basicCard}
              imageClassName={styles.cardImage}
              error={errorCard}
              card={card}
              flipped={flipped}
            />
          )}

          {/* Corners */}
          {!!username && <CornerOwned owned={card.owned} />}
          <CompanionTag card={card} />
        </button>
        {onCardUpdated && canEditDeck && !editionControls && (
          <div className={styles.controls}>
            <button className={`${styles.button} ${styles.green}`} onClick={handleAddCard}>
              <Icon name="plus" />
            </button>
            <span className={styles.buttonContainer}>
              <button
                className={`
                   ${styles.quantity}
                   ${styles.button}
                   ${localQuantity > 0 || card.otherPrintingQty > 0 ? styles.hasQuantity : ''}
               `}
                disabled>
                {localQuantity}
              </button>
              {card.otherPrintingQty > 0 && (
                <span tabIndex={0} className={styles.hasOtherPrintingQuantity}>
                  +{card.otherPrintingQty}
                </span>
              )}
            </span>

            <span className={styles.buttonContainer}>
              <button className={`${styles.button} ${styles.red}`} disabled={!localQuantity} onClick={handleRemoveCard}>
                <Icon name="minus" />
              </button>
            </span>

            <ArchidektDropdown
              triggerClassName={styles.dropdownIcon}
              menuClassName={styles.dropdown}
              options={[
                { label: addSide, onClick: () => handleMoveTo(card, 'Sideboard', !!card.qty) },
                { label: addMaybe, onClick: () => handleMoveTo(card, 'Maybeboard', !!card.qty) },
                {
                  label: addCommander,
                  onClick: () => handleMoveTo(card, 'Commander', false),
                  hidden: !isCommanderFormat,
                },
                { type: 'spacer' },
                {
                  label: 'Add to category',
                  icon: 'arrow right',
                  type: 'extras',
                  id: 'add-to-other',
                  className: styles.moveCategoryExtras,
                  options: [
                    ...Object.values(categories)
                      .map(category => ({
                        label: category.name,
                        onClick: () => handleMoveTo(card, category.name, !!card.qty),
                        hidden: ['Maybeboard', 'Sideboard', 'Commander'].includes(category.name),
                      }))
                      .sort((a, b) => (a.label > b.label ? 1 : -1)),
                  ],
                },
                { type: 'spacer' },
                { label: addNewEdition, onClick: () => handleHardAddNewEdition(card), hidden: !card.qty },
                { label: viewMore, onClick: handleCardClick },
                { label: allPrintings, onClick: () => handleViewAllPrintings(card) },
              ]}>
              <button className={`${styles.button} ${styles.blue}`}>
                <Icon name="ellipsis vertical" />
              </button>
            </ArchidektDropdown>
          </div>
        )}

        {editionControls && isDeckPage && (
          <div className={styles.controls}>
            {card.qty > 0 && (
              <span className={styles.buttonContainer}>
                <button className={`${styles.quantity} ${styles.button} ${styles.hasQuantity}`} disabled>
                  <Icon name="check" />
                </button>
              </span>
            )}
            <ArchidektDropdown
              menuClassName={styles.dropdown}
              options={[
                { label: switchToEdition, onClick: () => handleCardClick() },
                { label: addNewEdition, onClick: () => handleHardAddNewEdition(card) },
              ]}>
              <button className={`${styles.button} ${styles.blue}`}>
                <Icon name="ellipsis vertical" />
              </button>
            </ArchidektDropdown>
          </div>
        )}

        {DOUBLE_FACED_LAYOUTS.includes(card.layout) && (
          <button
            className={`${styles.button} ${styles.flipButton} ${flipped && styles.flipped}`}
            onClick={() => setFlipped(!flipped)}>
            <Icon name="refresh" />
          </button>
        )}
        {!hidePrices && (
          <div className={styles.actions}>
            <Prices card={card} />
          </div>
        )}
      </div>
    )
  },
  (prevProps: Props, nextProps: Props) => {
    // Only ever rerender this component if and only if the card has changed
    return isEqual(prevProps.card, nextProps.card)
  },
)

export default SearchedCard
