import { CardType, generateDefaultCategory, EDH_FORMATS, getCategoryName } from 'types/deck'
import { emptyTextArea } from 'types/editor'

import { DeckState, generateInitialDeckState } from 'redux/deck/initialState'
import { DeckActions, ModifyCardMapActionType } from 'redux/deck/actions'

import * as Deck from './actions/types'

const handler: (state: DeckState, action: DeckActions) => DeckState = (state = generateInitialDeckState(), action) => {
  switch (action.type) {
    case Deck.SET_DECK_STATE:
      return {
        ...state,
        ...action.payload,
      }

    case Deck.MODIFY_CARD_MAP:
      const updatedCardMap = { ...state.cardMap }
      const newCategories = { ...state.categories }

      action.payload.removals.forEach(id => delete updatedCardMap[id])
      action.payload.cards.forEach(card => {
        const primaryCategoryName = getCategoryName(card)

        if (!newCategories[primaryCategoryName])
          newCategories[primaryCategoryName] = generateDefaultCategory(primaryCategoryName)

        updatedCardMap[card.id] = {
          ...card,

          // If the card was passed in without a category for whatever reason, give it one
          categories:
            card.categories.length && card.categories[0] === primaryCategoryName
              ? card.categories
              : [primaryCategoryName],
        }
      })

      const menuCardId = state.menuCardId === action.payload.removals[0] ? undefined : state.menuCardId

      let actionStack = [...state.actionStack]
      let undoneActionStack = [...state.actionStack]

      if (action.payload.isUndo) {
        actionStack.pop()
        undoneActionStack = pushAction(action, undoneActionStack, state.cardMap)
      }

      if (action.payload.isRedo) {
        actionStack = pushAction(action, actionStack, state.cardMap)
        undoneActionStack.pop()
      }

      if (!action.payload.isUndo && !action.payload.isRedo) {
        actionStack = pushAction(action, actionStack, state.cardMap)
        undoneActionStack = []
      }

      return {
        ...state,
        cardMap: updatedCardMap,
        categories: newCategories,
        menuCardId: menuCardId,

        actionStack: action.payload.skipActionStack ? state.actionStack : actionStack,
        undoneActionStack: action.payload.skipActionStack ? state.undoneActionStack : undoneActionStack,
      }

    case Deck.DECK_ID_SUCCEEDED:
      return state

    case Deck.RESET_DECK:
      return {
        ...generateInitialDeckState(),
        view: state.view,
        stack: state.stack,
        sorting: state.sorting,
      }

    case Deck.DECK_PATCH_SUCCEEDED:
      const updatedCategories = state.categories

      if (action.payload.deckFormat && action.payload.deckFormat !== state.format) {
        if (!EDH_FORMATS.includes(state.format) && EDH_FORMATS.includes(action.payload.deckFormat)) {
          updatedCategories['Commander'] = generateDefaultCategory('Commander')
        }
      }

      return {
        ...state,
        name: action.payload.name || state.name,
        format: action.payload.deckFormat || state.format,
        edhBracket: action.payload.edhBracket || state.edhBracket,
        game: action.payload.game || state.game,
        description: action.payload.description || emptyTextArea,
        private: action.payload.private ?? state.private,
        theorycrafted: action.payload.theorycrafted ?? state.theorycrafted,
        unlisted: action.payload.unlisted ?? state.unlisted,
        categories: updatedCategories,
        customFeatured:
          action.payload.customFeatured === undefined ? state.customFeatured : action.payload.customFeatured,
      }

    default:
      return state
  }
}

const pushAction = (action: DeckActions, actionStack: DeckActions[], oldCardMap: Record<string, CardType>) => {
  const newActionStack = [...actionStack]

  switch (action.type) {
    case Deck.MODIFY_CARD_MAP:
      const modifications = action.payload.cards.filter(card => !!oldCardMap[card.id])
      const adds = action.payload.cards.filter(card => !oldCardMap[card.id])

      const newModifyAction: ModifyCardMapActionType = {
        type: Deck.MODIFY_CARD_MAP,
        payload: {
          cards: [
            // For cards being added back to
            ...action.payload.removals.map(id => ({ ...oldCardMap[id], deckRelationId: '' })),
            ...modifications.map(card => oldCardMap[card.id]),
          ],
          removals: adds.map(card => card.id),
          skipActionStack: false,
        },
      }
      newActionStack.push(newModifyAction)
      break
    default:
      return newActionStack.slice()
  }

  return newActionStack
}

export default handler
