import { get } from 'lodash'

import CookieService from 'services/cookie.service'
import { getPriceSources } from 'services/accountSettings.service'

import { ActiveState } from 'redux/active/actions/types'

import { defaultCard } from 'types/deck'
import {Cookies} from "react-cookie";

const getSettings = (cookies?: Cookies) => {
  const cookiesObject = cookies || CookieService
  return cookiesObject.get('settings')
}

export const generateIsMobile = () => {
  if (typeof window === 'undefined') return false

  return window.innerWidth <= 600
}

export const generateIsMidsized = () => {
  if (typeof window === 'undefined') return false

  return window.innerWidth <= 900
}

export const generateIsLarge = () => {
  if (typeof window === 'undefined') return false

  return window.innerWidth >= 1500
}

export const DEFAULT_CLIENT_FLAGS = {
  twitch: {
    edhrecast: false,
  },
}

export const generateActiveState = (cookies?: Cookies): ActiveState => ({
  card: defaultCard,
  modalActive: false,
  textActive: false,
  rightDetailsTab: 0,
  leftDetailsTab: 0,
  newDeckModalActive: false,
  isMobile: generateIsMobile(),
  isMidsize: generateIsMidsized(),
  isLarge: generateIsLarge(),
  recentDecks: { results: [], next: '', loading: true },
  yourDecks: { results: [], next: '', loading: true },
  recentlyViewed: { results: [], next: '', loading: true },
  followedDecks: { results: [], next: '', loading: true },
  totalCount: 0,
  deckResults: { results: [], next: '', loading: false },
  decksWithCard: { results: [], next: '', loading: false },
  noFocus: false,
  modals: {},

  priceSource: getPriceSources(undefined, cookies),
  filteredCards: [],
  highlightedCards: {},
  keybinds: get(getSettings(cookies), 'siteKeybinds', 0),
  isDeckPage: false,

  modalOverlay: {
    type: null,
    props: null,
  },

  toasts: [],
  searchOpen: false,
  optimizeCardOverlay: false,
  idleOverlay: false,
  multiSelectedIds: {},
  multiSelectedCollectionIds: {},

  stackOverlayIds: [],
  hideStackOverlay: false,

  globalColorTags: null,

  clientFlags: DEFAULT_CLIENT_FLAGS
})

export default generateActiveState
