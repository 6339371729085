import React from 'react'
import NextLink, { LinkProps } from 'next/link'

type Props = {
  children?: React.ReactNode
  className?: string
  target?: string
  rel?: string
  style?: React.CSSProperties
  tabIndex?: number
  title?: string
  about?: string
} & LinkProps

const Link = (props: Props) => {
  // defaults prefetch to false if `prefetch` is not true
  return (
    <NextLink {...props} prefetch={props.prefetch ?? false} shallow={false}>
      {props.children || null}
    </NextLink>
  )
}

export default Link
