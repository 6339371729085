import React from 'react'

import { CardType, FORMAT } from 'types/deck'

import { useAppSelector } from 'redux/hooks'

import Icon from 'components/elements/Icon'

import styles from './gameChangerLabel.module.scss'

type Props = {
  card: CardType
}

const GameChangerLabel = ({ card }: Props) => {
  const format = useAppSelector(state => state.deck.format)

  if (format !== FORMAT.EDH || !card.gameChanger) return null

  return (
    <div className={styles.container} title="Game Changer card">
      <span className={styles.cornerQuantity}>
        <Icon name="gem" />
      </span>
    </div>
  )
}

export default GameChangerLabel
