import { useState } from 'react'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'

import { CardType, FORMAT } from 'types/deck'

import ToastService from 'services/toast.service'

import PhatButton from 'components/formElements/PhatButton'
import DeckService from 'services/deck.service'
import Icon from 'components/elements/Icon'
import SimpleSpinner from 'components/elements/SimpleSpinner'
import Link from 'next/link'

import { generateDeckUrl } from 'utils/deckSeo'

import styles from './addToNewDeck.module.scss'

type Props = {
  card: CardType
}

const AddToNewDeck = ({ card }: Props) => {
  const router = useRouter()

  const [{ tbId: userId, tbRootFolderId: rootFolderId }] = useCookies(['tbId', 'tbRootFolderId'])

  const [loading, setLoading] = useState(false)

  const handleCreateNewDeckWithCard = () => {
    const cardSuperTypes = [...card.superTypes, ...(card.front?.superTypes || [])]
    const cardTypes = [...card.types, ...(card.front?.types || [])]

    const isCommander =
      (cardSuperTypes.includes('Legendary') && cardTypes.includes('Creature')) ||
      (card.text || card.front?.text || '').includes('can be your commander')

    let id = 0

    const body = {
      name: `Untitled ${card.name} deck`,
      deckFormat: isCommander ? FORMAT.EDH : FORMAT.CUSTOM,
      parentFolder: rootFolderId,
      private: false,
      extras: {},
    }

    setLoading(true)

    return DeckService.create(body)
      .then(deck => {
        id = deck.id

        return DeckService.add(id, [{ ...card, qty: 1, categories: isCommander ? ['Commander'] : [] }])
      })
      .then(() => router.push(generateDeckUrl(id, body.name)))
      .catch(err => {
        console.error(err)

        ToastService.create('Unable to create new deck', 'Deck Service')
      })
      .finally(() => setLoading(false))
  }

  if (!userId)
    return (
      <Link className={styles.fullWidth} href={`/sandbox?commanderAs=${card.name}`}>
        <PhatButton className={styles.fullWidth} icon="add circle">
          Create new deck
        </PhatButton>
      </Link>
    )

  return (
    <PhatButton disabled={loading} onClick={handleCreateNewDeckWithCard}>
      {loading ? (
        <>
          <SimpleSpinner size="xSmall" />
          Creating new deck...
        </>
      ) : (
        <>
          <Icon name="add circle" />
          Create new deck
        </>
      )}
    </PhatButton>
  )
}

export default AddToNewDeck
