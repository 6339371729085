import * as Folder from 'redux/folders/actions/types'

import { FoldersState, generateInitialFolderState } from 'redux/folders/initialState'
import { FoldersActions } from 'redux/folders/actions'
import { writeChangesToCookies } from 'redux/folders/helpers/cachedValues'

const handler: (state: FoldersState, action: FoldersActions) => FoldersState = (
  state = generateInitialFolderState(),
  action,
) => {
  switch (action.type) {
    case Folder.SET_FOLDER_STATE:
      writeChangesToCookies(action.payload)

      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}

export default handler
