import { GetServerSidePropsContext, NextPageContext } from 'next'
import Cookies from 'universal-cookie'

export const expirationDurations = {
  hour: 3600,
  day: 86400,
  week: 604800,
  month: 2592000,
  year: 31536000,
}

export function get<T = any>(path: string, serverCookies?: Record<string, any> | string): T {
  const cookies = new Cookies(serverCookies)

  const result = cookies.get(path)

  return result as T
}

export function set(key: string, value: any, age?: keyof typeof expirationDurations) {
  const cookies = new Cookies()

  const maxAge = age ? expirationDurations[age] : undefined

  // @ts-ignore - priority: 'High' is working fine, the TS definition is just wrong
  cookies.set(key, value, { path: '/', maxAge, sameSite: 'Lax', httpOnly: false, priority: 'High' })
}

export function remove(key: string) {
  const cookies = new Cookies()

  cookies.remove(key, { path: '/' })
}

export function purgeNonAuthCookies() {
  const cookies = new Cookies()
  const allCookies = cookies.getAll()

  // TODO - Is there a better way to manage these?
  // The issue is we need to define what we keep around so we can nuke everything that's not these. Ads fill cookies too much and it breaks the site.
  const keepAroundCookieKeys = [
    // Auth cookies
    'tbRefresh',
    'tbUser',
    'tbJwt',
    'tbId',
    'tbR',
    'tbTier',
    'tbRootFolder',
    'settings',
    'tbBanner',

    // Redux states
    'collectionAddCardSettings',
    'cacheable-state',
    'collectionV2-cache',

    // Misc settings and options
    'theme',
    'archidekt-search-orderby',
    'collectionAddOption',
    'optimizeCardsOptions',
  ]

  const cookieKeys = Object.keys(allCookies)

  for (const cookieKey of cookieKeys) {
    if (!keepAroundCookieKeys.includes(cookieKey)) cookies.remove(cookieKey, { path: '/' })
  }
}

export function getTotalCookieSize() {
  const cookies = new Cookies()
  const allCookies = cookies.getAll()

  return new Blob([JSON.stringify(allCookies)]).size
}

export function logout() {
  remove('tbRefresh')
  remove('tbUser')
  remove('tbJwt')
  remove('tbId')
  remove('tbDecks')
  remove('tbR')
  remove('tbTier')
  remove('tbRootFolder')
  remove('settings')
  remove('tbBanner')
  remove('tbUniqueId')
}

export function serverLogout(ctx: NextPageContext) {
  ctx.res?.setHeader('Set-Cookie', [
    'tbRefresh=deleted; path=/; Max-Age=0',
    'tbUser=deleted; path=/; Max-Age=0',
    'tbJwt=deleted; path=/; Max-Age=0',
    'tbId=deleted; path=/; Max-Age=0',
    'tbDecks=deleted; path=/; Max-Age=0', // This is no longer being set at login, we can remove it evenutally, but for now let's let users remove it themselves by logging out
    'tbR=deleted; path=/; Max-Age=0',
    'tbTier=deleted; path=/; Max-Age=0',
    'tbRootFolder=deleted; path=/; Max-Age=0',
    'settings=deleted; path=/; Max-Age=0',
    'tbBanner=deleted; path=/; Max-Age=0',
    'tbUniqueId=deleted; path=/; Max-Age=0',
  ])
}

// Time in seconds
// 90 seconds default is arbitrary
export function setServerCookie(cookies: Record<string, string>, ctx: GetServerSidePropsContext, age: number = 90) {
  ctx.res?.setHeader(
    'Set-Cookie',
    Object.entries(cookies).map(([key, value]) => `${key}=${value}; path=/; Max-Age=${age}; SameSite=Lax;`),
  )
}

export default {
  get,
  set,
  remove,
  logout,
  serverLogout,
  purgeNonAuthCookies,
  getTotalCookieSize,
  setServerCookie,
}
